import { Component, OnInit } from '@angular/core';
import { FormGroup, FormControl, FormBuilder, Validators, FormControlName, ValidatorFn, AbstractControl } from '@angular/forms';
import { UserService } from '../../user.service';
import {MatSnackBar} from '@angular/material/snack-bar';
import { ActivatedRoute } from '@angular/router';


@Component({
  selector: 'app-forgot-pin',
  templateUrl: './forgot-pin.component.html',
  styleUrls: ['./forgot-pin.component.scss']
})
export class ForgotPinComponent implements OnInit {
  passwordForm: FormGroup;
  sendingEmail: boolean = false;
  isDevelopment: boolean = false;
  locationId;
  

  constructor(private fb: FormBuilder, private _snackBar: MatSnackBar, private userService: UserService, private route: ActivatedRoute) { }

  ngOnInit(): void {
    this.buildPasswordForm();

    this.locationId = this.route.snapshot.params.locationId
  }

  buildPasswordForm() {
    this.passwordForm = this.fb.group({
      email: [null, [Validators.required, Validators.pattern('[a-zA-Z0-9._%+-]+@[a-zA-Z0-9.-]+')]]
    });
  }

 

  submitPasswordForm() {
    this.sendingEmail = true;
   // this.hasSentEmail = true;
    this.userService.emailMeMyPin(this.passwordForm.get('email').value, this.locationId).subscribe(r => {
      this.sendingEmail = false;
      this._snackBar.open("Reset email sent", "Success", {
        duration: 3000,
      });
      console.log(r, 'response from api')
    });
  }

  backToLogin(){

  }

}
