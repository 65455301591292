<div align="middle">
  <mat-card *ngIf="location !== undefined; else noLocation">
    <h2>
      {{location.centre}}
    </h2>

    <form class="example-form" novalidate autocomplete="off" (ngSubmit)="login()" [formGroup]="loginForm">
      <div align="middle">
        <div class="form-group" [ngClass]="{'has-error': isPasswordGroupTouchedDirtyAndInvalid('userId') }">
          <!-- <label class="control-label required" for="password" >Password</label> -->
          <mat-form-field class="example-full-width" >
            <mat-label>{{ 'userId' | translate }}</mat-label>
            <input
              matInput
              formControlName="userId"
              name="userId"
              required
              id="userId"
              type="text"
              placeholder="{{'userIdPlaceholder' | translate}}"
            />
          </mat-form-field>

          <mat-error *ngIf="isPasswordGroupTouchedDirtyAndInvalid('userId')">
            <span *ngIf="loginForm.get('userId').errors.required" [translate]="'pleaseEnterYour4DigitUserId'">
            </span>

            <span *ngIf="loginForm.get('userId').errors.maxlength" [translate]="'itMustBe4DigitsLong'">
            </span>
          </mat-error>

          <span *ngIf="isPasswordGroupTouchedDirtyAndInvalid('userId') && loginForm.get('userId').errors.minlength" class="grey" >
            {{loginForm.get('userId').errors.minlength.requiredLength - loginForm.get('userId').errors.minlength.actualLength}} digits to go
          </span>
        </div>
      </div>

      <div class="button-margin" align="middle">
        <div *ngIf="isLoginFail" style="padding-bottom: 22px;">{{ 'pinNotFound' | translate }}</div>
        <button mat-button type="button" routerLink="/forgot-pin/{{locationId}}" [disabled]="loading || !activeURL.includes('/login/')">{{ 'forgotPin' | translate }}</button>

        <button mat-flat-button color="primary" [disabled]="loading || isPasswordGroupTouchedDirtyAndInvalid('userId') || !activeURL.includes('/login/')" align="middle" >
          <span *ngIf="!loading">{{ 'Login' | translate }}</span>
          <mat-spinner class="loading-spinner" diameter="20"  style="margin:0 auto;" *ngIf="loading" ></mat-spinner>
        </button>
      </div>

    </form>
  </mat-card>

  <ng-template #noLocation>
    <mat-card>
      <h2>No Location. Redirecting...</h2>
    </mat-card>
  </ng-template>
</div>
