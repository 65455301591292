import { BrowserModule } from '@angular/platform-browser';
import { NgModule } from '@angular/core';

import { AppRoutingModule } from './app-routing.module';
import { AppComponent } from './app.component';
import { BrowserAnimationsModule } from '@angular/platform-browser/animations';
import { LoginComponent } from './learner/login/login.component';
import { FormsModule, ReactiveFormsModule } from '@angular/forms';
import { MaterialsModule } from './material/materials.module';

import {TranslateModule, TranslateLoader} from '@ngx-translate/core';
import {TranslateHttpLoader} from '@ngx-translate/http-loader';
import { HttpClient, HttpClientModule } from '@angular/common/http';
import { UserService } from './user.service';
import { LoginResolverService } from './login-resolver.service';
import { ForgotPinComponent } from './learner/forgot-pin/forgot-pin.component';
import { MyAccountComponent } from './learner/my-account/my-account.component';
import { WelcomeComponent } from './learner/welcome/welcome.component';
import { BookLeaveComponent } from './learner/book-leave/book-leave.component';
import { FlexLayoutModule } from '@angular/flex-layout';
import { ApproveLeaveComponent } from './learner/approve-leave/approve-leave.component';
import { LandingPageComponent } from './landing-page/landing-page.component';

export function HttpLoaderFactory(httpClient: HttpClient) {
  return new TranslateHttpLoader(httpClient, "/assets/i18n/", "-v1.json");
}

@NgModule({
  declarations: [
    AppComponent,
    LoginComponent,
    ForgotPinComponent,
    MyAccountComponent,
    WelcomeComponent,
    BookLeaveComponent,
    ApproveLeaveComponent,
    LandingPageComponent
  ],
  imports: [
    BrowserModule,
    AppRoutingModule,
    BrowserAnimationsModule,
    FormsModule,
    ReactiveFormsModule,
    MaterialsModule,
    FlexLayoutModule,
    HttpClientModule,
   //NgxDaterangepickerMd.forRoot(),
    TranslateModule.forRoot({
      loader: {
        provide: TranslateLoader,
        useFactory: HttpLoaderFactory,
        deps: [HttpClient]

      }
    })
  ],
  providers: [HttpClient, UserService, LoginResolverService],
  bootstrap: [AppComponent]
})
export class AppModule { }
