<mat-card  align="middle">
    <h1 title="Approve Authorised Absence">Approve AA</h1>

<div *ngIf="leaveToApproveByLearner.length > 0">
    <!-- <div fxLayout="row" class="headers">
        <div fxFlex="25">Start date</div>
        <div fxFlex="25">End date</div>
        <div fxFlex="25">Duration</div>

    </div> -->
    <div *ngFor="let leave of leaveToApproveByLearner">
        <div fxLayout="row" fxLayoutGap="20px">
            <div fxFlex="65">{{leave.dateString}} 
                <span class="time">
                {{leave.timein?.hours}}:00-{{ leave.timeout?.hours}}:00
            </span></div>
    
            <div fxFlex="35">{{leave.comments}}</div>
        </div>
    </div>
</div>
        <div align="center">
            <button mat-button type="button" routerLink="/my-account">{{ 'backToAccountHomePage' | translate }}</button>
        </div>
    <div align="center" class="margin-top">
      <!-- // reject button?  -->
        <button mat-flat-button color="primary" type="buttn" (click)="approveAA()" class="button-margin" >
            <span >{{ 'approveAll' | translate }}</span> 
            <!-- <mat-spinner class="loading-spinner" style="margin:0 auto;"  diameter="20"></mat-spinner> -->
        </button>
    </div>

</mat-card>