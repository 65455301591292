import { ChangeDetectorRef, Component, OnInit } from '@angular/core';
import { Router } from '@angular/router';
import { UserService } from '../../user.service';
import * as moment from 'moment';
import { FormControl, FormGroup } from '@angular/forms';
import { MatSnackBar } from '@angular/material/snack-bar';

@Component({
  selector: 'app-book-leave',
  templateUrl: './book-leave.component.html',
  styleUrls: ['./book-leave.component.scss']
})
export class BookLeaveComponent implements OnInit {
  centreName
  learnerName
  locationId
  isSignedIn
  userId
  isLoading: boolean = false;
  isLoadingHolidayInfo: boolean = true;
  holidayInfo: any;
  absenceTypeSelected: string = "Holiday"
  absenceTypes: string[] = ["Holiday", "AA"]
  range = new FormGroup({
    start: new FormControl(),
    end: new FormControl(),
    start9amOrMidday: new FormControl(),
    endMiddayOrEnd: new FormControl()
  });

  minDate
  maxDate
  duration : number = null
  isDisplayOneDayButton: boolean = false;
  endAfterStart = false;

  isHalfDayStart: boolean = false;
  isHalfDayEnd: boolean = false;


  constructor(private router: Router, private userService: UserService, private cdr: ChangeDetectorRef, private snackBar: MatSnackBar) { }

  ngOnInit(): void {
    const currentYear = new Date().getFullYear();
    const now = new Date()
    this.minDate = new Date(currentYear, 0, 1);
    this.maxDate = new Date(currentYear + 1, 11, 31);

    this.centreName = localStorage.getItem("centreName")
    this.learnerName = localStorage.getItem("learnerName")
    this.locationId = localStorage.getItem("locationId")
    this.isSignedIn = localStorage.getItem("isSignedIn") == "true"
    console.log("getting is signed in", this.isSignedIn)
    this.userId = localStorage.getItem("userId")

    this.getLeaveDetails();

  }

  getLeaveDetails() {
    this.userService.getLeaveDetails(this.userId).subscribe(response => {
      console.log("response", response)
      this.isLoadingHolidayInfo = false;
      this.holidayInfo = response
    })
  }

  selectOneDayDuration() {
    //this.range.value.end = this.range.value.start
    var startDate = this.range.value.start
    let startDateMoment: moment.Moment = moment(this.range.value.start);
    this.range.get("end").setValue(startDate)

    // if (startDateMoment.isoWeekday() !== 6 && startDateMoment.isoWeekday() !== 7) {
      if(this.isHalfDayStart){
        this.duration = 0.5
      }
      else {
        this.duration = 1
      }

      this.isDisplayOneDayButton = false
    // }
    // else {
    //   this.duration = 0
    //   this.isDisplayOneDayButton = false
    // }
    this.cdr.detectChanges()
  }


  myFilter = (d: Date | null): boolean => {
    const day = (d || new Date()).getDay();
    // Prevent Saturday and Sunday from being selected.
    return day !== 0 && day !== 6;
  }

  weekendsDatesFilter = (d: Date): boolean => {
    const day = d.getDay();

    /* Prevent Saturday and Sunday for select. */
    return day !== 0 && day !== 6 ;
  }

  bookLeave() {
    var startDate = this.range.value.start
    var endDate = this.range.value.end
    var startDateString = moment(startDate).format('YYYY-MM-DD');
    var endDateString = moment(endDate).format('YYYY-MM-DD');
    var isHoliday = this.absenceTypeSelected == "Holiday"

    var dateDiff = moment(endDate).diff(moment(startDate), 'days')
    console.log("dateDiff", dateDiff)
    const endBeforeStart = dateDiff < 0;
    if(dateDiff <= 31 && !endBeforeStart)
    {
      this.userService.bookLeave(this.userId, startDateString, endDateString, isHoliday, this.duration.toString(), this.isHalfDayStart, this.isHalfDayEnd).subscribe(response => {
        console.log("re", response)
        if (response) {

          this.snackBar.open("Leave requested", "Success", {
            duration: 3000,
          }).afterDismissed().subscribe(info=> {localStorage.clear()
            this.router.navigate([`/login/${this.locationId}`]);});;
        }
      });
    } else {
      this.snackBar.open("Please select a date range of a month or less", "Close", {
        duration: 3000,
      });
    }

  }

  dateChange() {
    console.log("range", this.range.value.start, this.range.value.end)
    var start = this.range.value.start
    var end = this.range.value.end

    // if(!this.weekendsDatesFilter(start)){
    //   let startDate: moment.Moment = moment(this.range.value.start);
    //   startDate = startDate.add(1, 'days');
    //   this.range.get("start").setValue(startDate);
    //   this.snackBar.open("Please select a week day", "Error", {
    //     duration: 3000,
    //   });
    // }


    // if(end && !this.weekendsDatesFilter(end)){
    //   let endDate: moment.Moment = moment(this.range.value.end);
    //   endDate = endDate.add(1, 'days');
    //   this.range.get("end").setValue(endDate);
    //   this.snackBar.open("Please select a week day", "Error", {
    //     duration: 3000,
    //   });
    // }

    if(moment(end).diff(moment(start), 'days') < 0){
      let endDate: moment.Moment = moment(this.range.value.end);
      endDate = endDate.add(1, 'days');
      this.endAfterStart = false;
      this.range.get("end").setValue(endDate);
      this.snackBar.open("Please select an end date after the start date", "Error", {
        duration: 3000,
      });
    } else if(end && start) {
      this.endAfterStart = true
    }

    //  console.log(startDate, endDate)
    this.isDisplayOneDayButton = false
    if (start && !end) {
      this.isDisplayOneDayButton = true
      this.duration = null
    }

console.log(start , end ,  this.range.value.start9amOrMidday , this.range.value.endMiddayOrEnd)
    if (start && end &&  this.range.value.start9amOrMidday && this.range.value.endMiddayOrEnd)  {
      let startDate: moment.Moment = moment(this.range.value.start);
      let endDate: moment.Moment = moment(this.range.value.end);
      // var a = startDate.diff(endDate, 'days')

      var durationIncludingWeekends = moment.duration(endDate.diff(startDate)).asDays();
      // console.log("a", a)
      //  a = a*-1
      this.duration = durationIncludingWeekends + 1;//this.countWeekdays(startDate, durationIncludingWeekends);

      if (this.isHalfDayStart) {
        this.duration = this.duration - 0.5
      }

      if (this.isHalfDayEnd && this.duration > 1 || (this.isHalfDayEnd && this.isHalfDayStart && this.duration >= 1)) {
        this.duration = this.duration - 0.5
      }

      // Half same day
      if((startDate.isSame(endDate) && !this.isHalfDayStart && this.isHalfDayEnd)
          || (startDate.isSame(endDate) && this.isHalfDayStart && !this.isHalfDayEnd)) {
        this.duration = 0.5
      }

      // same time on same day
      if(startDate.isSame(endDate) && this.isHalfDayStart && this.isHalfDayEnd) {
        this.duration = 0
      }

    }



    // console.log("date range change called", event)
    // let startDate: moment.Moment = event.startDate;
    // let endDate: moment.Moment = event.endDate;

    // if (startDate && endDate) {
    //   console.log("dates selected", startDate, endDate)
    // }
  }

  countWeekdays(date, length) {
    var weekDaysPassed = 0;
    var dateTracker = moment(date);
    for (let i = 0; i <= length; i++) {
      if (dateTracker.isoWeekday() !== 6 && dateTracker.isoWeekday() !== 7) {
        weekDaysPassed++;
      }

      dateTracker = dateTracker.add(1, 'days');
    }



    return weekDaysPassed;
  }

  halfDayStartChanged() {
    this.isHalfDayStart = this.range.value.start9amOrMidday == "Midday (12pm)";
    console.log("start clicked", this.isHalfDayStart);

    if (this.isHalfDayStart) {
      if (this.duration != null) {
        this.duration = this.duration - 0.5
      }
    }
    else{
      if (this.duration != null) {
        this.duration = this.duration + 0.5
      }
    }

    this.dateChange();
  }

  halfDayEndChanged() {
    this.isHalfDayEnd = this.range.value.endMiddayOrEnd == "Midday (12pm)";
    console.log("end clicked", this.isHalfDayEnd);
    if (this.isHalfDayEnd) {
      if (this.duration != null) {
        this.duration = this.duration - 0.5
      }
    }
    else{
      if (this.duration != null) {
        this.duration = this.duration + 0.5
      }
    }

    this.dateChange();
  }

  //todo:
  addWeekdays(date, days) {
    date = moment(date); // use a clone
    while (days > 0) {
      date = date.add(1, 'days');
      // decrease "days" only if it's a weekday.
      if (date.isoWeekday() !== 6 && date.isoWeekday() !== 7) {
        days -= 1;
      }
    }
    return days;
  }



}
