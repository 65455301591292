import { NgModule } from '@angular/core';
import { Routes, RouterModule } from '@angular/router';
import { ApproveLeaveComponent } from './learner/approve-leave/approve-leave.component';
import { BookLeaveComponent } from './learner/book-leave/book-leave.component';
import { ForgotPinComponent } from './learner/forgot-pin/forgot-pin.component';
import { LoginResolverService } from './login-resolver.service';
import { LandingPageResolver } from './landing-page/landing-page.resolver';
import { LoginComponent } from './learner/login/login.component';
import { MyAccountComponent } from './learner/my-account/my-account.component';
import { WelcomeComponent } from './learner/welcome/welcome.component';
import {LandingPageComponent } from './landing-page/landing-page.component';

const routes: Routes = [
  { path: '', component: LandingPageComponent, resolve: { centres: LandingPageResolver }},
  { path: 'login/:locationId', component: LoginComponent, resolve: { location: LoginResolverService } },
  { path: 'login/:locationId/:pin', component: LoginComponent, resolve: { location: LoginResolverService } },
  { path: 'forgot-pin/:locationId', component: ForgotPinComponent },
  { path: 'my-account', component: MyAccountComponent },
  { path: 'welcome', component: WelcomeComponent },
  { path: 'book-leave', component: BookLeaveComponent },
  { path: 'approve-leave', component: ApproveLeaveComponent },
  { path: '**', redirectTo: '', pathMatch: 'full' },
];

@NgModule({
  imports: [RouterModule.forRoot(routes)],
  exports: [RouterModule]
})
export class AppRoutingModule { }
