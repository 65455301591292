import { Component, OnInit } from '@angular/core';
import { MatSnackBar } from '@angular/material/snack-bar';
import { ActivatedRoute, Router } from '@angular/router';
import { UserService } from '../../user.service';

@Component({
  selector: 'app-approve-leave',
  templateUrl: './approve-leave.component.html',
  styleUrls: ['./approve-leave.component.scss']
})
export class ApproveLeaveComponent implements OnInit {
  leaveToApproveByLearner;
  userId;

  constructor(private userService: UserService, private snackBar: MatSnackBar, private router: Router) { }

  ngOnInit(): void {
    this.leaveToApproveByLearner = JSON.parse(localStorage.getItem("leaveToApproveByLearner"));
    this.userId = localStorage.getItem("userId")
  }

  approveAA(){
    this.userService.approveLeaveAsLearner(this.userId, this.leaveToApproveByLearner).subscribe(response => {
      localStorage.setItem("leaveToApproveByLearner", JSON.stringify([]))
      this.snackBar.open("Approved", "Success", {
        duration: 3000,
      });
      this.router.navigate(["my-account"])
    })
  }

}
