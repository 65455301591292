import { Injectable } from '@angular/core';
import {
  Router, Resolve,
  RouterStateSnapshot,
  ActivatedRouteSnapshot
} from '@angular/router';
import { Observable, of } from 'rxjs';

@Injectable({
  providedIn: 'root'
})
export class LandingPageResolver implements Resolve<any> {
  resolve(route: ActivatedRouteSnapshot, state: RouterStateSnapshot): Observable<any> {
    // get list of centres and ids.
    return of([
      {'id': 39, 'name' : 'Aberdare', 'subcontractor' : 'ACT'},
      {'id': 1, 'name' : 'Bridgend', 'subcontractor' : 'ACT'},
      {'id': 2, 'name' : 'Caerphilly', 'subcontractor' : 'ACT'},
      {'id': 4, 'name' : 'Hadfield Road', 'subcontractor' : 'ACT'},
      {'id': 117, 'name' : 'Pontypool', 'subcontractor' : 'ACT'},
      {'id': 5, 'name' : 'Ocean Park House', 'subcontractor' : 'ACT'},
      {'id': 19, 'name' : 'Cardiff', 'subcontractor' : 'MPCT'},
      {'id': 49, 'name' : 'Bangor', 'subcontractor' : 'MPCT'},
      {'id': 50, 'name' : 'Bridgend', 'subcontractor' : 'MPCT'},
      {'id': 51, 'name' : 'Newport', 'subcontractor' : 'MPCT'},
      {'id': 52, 'name' : 'Rhondda', 'subcontractor' : 'MPCT'},
      {'id': 53, 'name' : 'Rhymney', 'subcontractor' : 'MPCT'},
      {'id': 54, 'name' : 'Wrexham', 'subcontractor' : 'MPCT'},
      {'id': 55, 'name' : 'Head Office', 'subcontractor' : 'MPCT'},
      {'id': 72, 'name' : 'Cardiff Sports', 'subcontractor' : 'MPCT'}
    ]);
  }
}
