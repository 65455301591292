<div class="app-container">
    <!-- <div class="menu-container">
        <img #logoImg src= "../assets/{{logo}}" alt="act logo" routerLink="/" class="button-link">
        <mat-nav-list>
            <div>
                <span class="menu-option button-link" [matMenuTriggerFor]="menu">Learner <mat-icon style="position: relative; top: 5px;">expand_more</mat-icon></span>
                    <mat-menu #menu="matMenu">
                        <button class="button-link" mat-menu-item *ngFor="let loc of menuLocations" routerLink="{{loc.url}}">
                            <span>{{loc.name}}</span>
                        </button>
                    </mat-menu>
                <span class="menu-option button-link" routerLink = "/employerlogin">Employer</span>
            </div>
        </mat-nav-list>
    </div> -->

    <div class="content-container">
        <main>
            <router-outlet></router-outlet>
        </main>
    </div>
</div>
