import { Component, OnInit } from '@angular/core';
import { MatSnackBar } from '@angular/material/snack-bar';
import { Router } from '@angular/router';
import { UserService } from '../../user.service';
import { SignOutLearner } from 'src/app/shared/interfaces/sign-out-learner';

@Component({
  selector: 'app-my-account',
  templateUrl: './my-account.component.html',
  styleUrls: ['./my-account.component.scss']
})
export class MyAccountComponent implements OnInit {
  centreName
  learnerName
  locationId
  isSignedIn
  userId
  isLoading: boolean = false;
  leaveToApproveByLearner: any[];
  disable: boolean = false;
  isACT: boolean = false;

  satisfactionButtons = [
    {value: 0, src: "assets/icons/satisfaction/very-sad-face.png", alt: "Very Dissatisfied"},
    {value: 1, src: "assets/icons/satisfaction/sad-face.png", alt: "Dissatisfied"},
    {value: 2, src: "assets/icons/satisfaction/neutral-face.png", alt: "Neutral"},
    {value: 3, src: "assets/icons/satisfaction/happy-face.png", alt: "Happy"},
    {value: 4, src: "assets/icons/satisfaction/very-happy-face.png", alt: "Very Happy"},
  ]

  satisfactionValue: number | null = null;
  satisfactionComment: string | null = null;

  constructor(
    private router: Router,
    private userService: UserService,
    private snackBar: MatSnackBar,
    ) { }
  public $time: Date = new Date();

  ngOnInit(): void {
    if(localStorage.getItem("isSignedIn") === null) {
      this.backToLogin();
      return;
    }

    this.centreName = localStorage.getItem("centreName");
    this.learnerName = localStorage.getItem("learnerName");
    this.locationId = localStorage.getItem("locationId");
    this.isSignedIn = localStorage.getItem("isSignedIn") == "true";
    this.userId = localStorage.getItem("userId");
    this.isACT = localStorage.getItem('subcontractorId') === '1' && this.locationId !== '107';

    console.log(this.locationId, this.centreName);

    this.userService.getLeavePendingLearnerApprovalDetails(this.userId).subscribe(response => {
      //console.log("r", response)
      this.leaveToApproveByLearner = response;
      localStorage.setItem("leaveToApproveByLearner", JSON.stringify(this.leaveToApproveByLearner));
  //  this.cdr.det
    });
  }

  backToLogin(){
    if(localStorage.getItem("locationId") === null) {
      return this.router.navigate(['']);
    }
    localStorage.clear();
    this.router.navigate([`login/${this.locationId }`]);
  }

  signIn(){
    this.isLoading = true;
    this.userService.signinLearner(this.locationId, this.userId).subscribe(response => {
      if(response) {
        localStorage.setItem('isSignedIn', "true");
        this.isLoading = false;
        this.disable = true;
        this.backToLogin();
        this.snackBar.open("You are now Signed In!", "Close", {
          duration: 3000,
        });
      } else {
        this.isLoading = false;
        this.snackBar.open("Error Signing In: Please try again in a few mins", "Close", {
          duration: 3000,
        });
      }
    });
  }

  signOut(){
    this.isLoading = true;
    const idOfSignInRecord =  +localStorage.getItem('IdOfSignInRecord');
    console.log("info", this.satisfactionComment, this.satisfactionValue);
    const data: SignOutLearner = {
      locationId: this.locationId,
      userId: this.userId,
      idOfSignInRecord: idOfSignInRecord,
      satisfactionComment: this.satisfactionComment,
      satisfactionValue: this.satisfactionValue,
    }

    this.userService.signoutLearner(data).subscribe({
      next: () => {
        localStorage.setItem('isSignedIn', "false");
        this.isLoading = false;
        this.disable = true;
      },
      error: err => {
        this.isLoading = false;
        console.log(err.error);
        this.snackBar.open("Error Signing Out: Please try again in a few mins", "Close", {
          duration: 3000,
        });
      },
      complete: () => {
        this.backToLogin();
        this.snackBar.open("Signed Out", "Success", {
          duration: 3000,
        });
      }
    });
  }

  setSatisfaction(value: number) : void
  {
    this.satisfactionValue = value;
  }


}
