<mat-card class="centre-card"  align="middle">
  <h1>Hello {{learnerName}}</h1>
  <h2>Welcome to {{centreName}}</h2>
  {{ $time  | date : 'dd/MM/y - HH:mm' }}
  <form >
    <div class="button-margin" align="middle">
      <button mat-button type="button" (click)="backToLogin()" >{{ 'notYouBackToLogin' | translate }}</button>

      <button mat-flat-button color="primary" type="button" routerLink="/my-account" class="button-margin">
          <span >{{ 'Continue to my account' | translate }}</span>
      </button>
    </div>
  </form>
</mat-card>
