import { Component, ViewChild } from '@angular/core';
import { ResolveStart, Router } from '@angular/router';
import { TranslateService } from '@ngx-translate/core';



@Component({
  selector: 'app-root',
  templateUrl: './app.component.html',
  styleUrls: ['./app.component.scss'],
})
export class AppComponent {

/*
  loginLocations: any= [
    {'url': '/', 'name' : 'Home',  'logoName': 'attendance-logo.png', 'width' : '50px' },
    {'url': '/my-account', 'name' : 'Home', 'logoName': 'attendance-logo.png', 'width' : '50px'},
    {'url': '/employerlogin', 'name' : 'Home',  'logoName': 'attendance-logo.png', 'width' : '50px' },
    {'url': '/login/39', 'name' : 'ACT Aberdare', 'logoName': 'logo.png', 'width' : '100px'},
    {'url': '/login/1', 'name' : 'ACT Bridgend', 'logoName': 'logo.png', 'width' : '100px'},
    {'url': '/login/2', 'name' : 'ACT Caerphilly', 'logoName': 'logo.png', 'width' : '100px'},
    {'url': '/login/4', 'name' : 'ACT Hadfield Road', 'logoName': 'logo.png', 'width' : '100px'},
    {'url': '/login/5', 'name' : 'ACT Ocean Park House', 'logoName': 'logo.png', 'width' : '100px' },
    {'url': '/login/19', 'name' : 'MPCT Cardiff', 'logoName': 'mpct-logo.svg', 'width' : '100px' },
    {'url': '/login/49', 'name' : 'MPCT Bangor', 'logoName': 'mpct-logo.svg', 'width' : '100px' },
    {'url': '/login/50', 'name' : 'MPCT Bridgend', 'logoName': 'mpct-logo.svg', 'width' : '100px' },
    {'url': '/login/51', 'name' : 'MPCT Newport', 'logoName': 'mpct-logo.svg', 'width' : '100px' },
    {'url': '/login/52', 'name' : 'MPCT Rhondda', 'logoName': 'mpct-logo.svg', 'width' : '100px' },
    {'url': '/login/53', 'name' : 'MPCT Rhymney', 'logoName': 'mpct-logo.svg', 'width' : '100px' },
    {'url': '/login/54', 'name' : 'MPCT Wrexham', 'logoName': 'mpct-logo.svg', 'width' : '100px' },
    {'url': '/login/55', 'name' : 'MPCT Head Office', 'logoName': 'mpct-logo.svg', 'width' : '100px'},
    {'url': '/login/72', 'name' : 'MPCT Cardiff Sports', 'logoName': 'mpct-logo.svg', 'width' : '100px'}
  ]
*/

  constructor(public translate: TranslateService)
  {
    translate.addLangs(['en', 'we']);
    translate.setDefaultLang('en');
  }

}
