<div style="padding-top: 20vh">
  <mat-card style="width: 400px; margin: 0 auto" align="center">
    <mat-card-title>
      Select a centre
    </mat-card-title>

    <mat-card-content>

      <div style="padding-top: 20px">
        <mat-form-field appearance="outline">
          <mat-label>Centre</mat-label>
          <mat-select #centreList disableOptionCentering="true" (selectionChange)="centreSelected($event)">
            <ng-container *ngFor="let group of centresGrouped">
              <mat-optgroup *ngIf="group.centres.length > 0" [label]="group.name">
                <mat-option *ngFor="let centre of group.centres" [value]="centre">
                  {{ centre.name }}
                </mat-option>
              </mat-optgroup>
            </ng-container>
          </mat-select>
        </mat-form-field>
      </div>

    </mat-card-content>
  </mat-card>
</div>
