import { Injectable } from '@angular/core';
import { Resolve, ActivatedRouteSnapshot } from '@angular/router';
import { UserService } from './user.service';

@Injectable()
export class LoginResolverService implements Resolve<any> {


  constructor(private userService: UserService) { }

  resolve(route: ActivatedRouteSnapshot): any {
    console.log("resolver called")
    return this.userService.getLocationDetails(route.params['locationId']);
  }
}
