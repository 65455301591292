import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { MatNativeDateModule, MAT_DATE_LOCALE } from  '@angular/material/core';
import { MatSnackBarModule} from  '@angular/material/snack-bar';
import { MatIconModule} from  '@angular/material/icon';
import { MatDialogModule} from  '@angular/material/dialog';
import { MatButtonModule} from  '@angular/material/button';
import { MatTableModule} from  '@angular/material/table';
import { MatPaginatorModule} from  '@angular/material/paginator';
import { MatSortModule} from  '@angular/material/sort';
import { MatTabsModule} from  '@angular/material/tabs';
import { MatCheckboxModule} from  '@angular/material/checkbox';
import { MatToolbarModule} from  '@angular/material/toolbar';
import { MatCardModule} from  '@angular/material/card';
import { MatFormFieldModule} from  '@angular/material/form-field';
import { MatProgressSpinnerModule} from  '@angular/material/progress-spinner';
import { MatProgressBarModule} from  '@angular/material/progress-bar';
import { MatInputModule} from  '@angular/material/input';
import {MatGridListModule} from '@angular/material/grid-list';
import { MatDatepickerModule} from  '@angular/material/datepicker';
import { MatRadioModule} from  '@angular/material/radio';
import { MatSelectModule} from  '@angular/material/select';

import { MatSliderModule} from  '@angular/material/slider';
import { MatDividerModule} from  '@angular/material/divider';
import { MatSidenavModule } from '@angular/material/sidenav';
import { MatListModule } from '@angular/material/list';
import { MatChipsModule } from '@angular/material/chips';
import { MatStepperModule } from '@angular/material/stepper';
import { MatRippleModule } from '@angular/material/core';
import {MatMenuModule} from '@angular/material/menu';
import {MatExpansionModule} from '@angular/material/expansion';
import {MatAutocompleteModule} from '@Angular/material/autocomplete'


import {MatButtonToggleModule} from '@angular/material/button-toggle';


import {DateAdapter, MAT_DATE_FORMATS } from '@angular/material/core';


@NgModule({
    declarations: [],
    imports: [
        CommonModule,
        MatTabsModule,
        MatDividerModule,
        MatSliderModule,
        MatSelectModule,
        MatRadioModule,
        MatDatepickerModule,
        MatNativeDateModule,
        MatSnackBarModule,
        MatIconModule,
        MatDialogModule,
        MatButtonModule,
        MatSortModule,
        MatTableModule,
        MatTabsModule,
        MatCheckboxModule,
        MatToolbarModule,
        MatCardModule,
        MatFormFieldModule,
        MatProgressSpinnerModule,
        MatProgressBarModule,
        MatInputModule,
        MatPaginatorModule,
        MatGridListModule,
        MatSidenavModule,
        MatListModule,
        MatChipsModule,
        MatStepperModule,
        MatRippleModule,
        MatMenuModule,
        MatExpansionModule,
    ],
    exports: [
        CommonModule,
        MatTabsModule,
        MatDividerModule,
        MatSliderModule,
        MatSelectModule,
        MatRadioModule,
        MatNativeDateModule,
        MatDatepickerModule,
        MatSnackBarModule,
        MatIconModule,
        MatDialogModule,
        MatButtonModule,
        MatSortModule,
        MatTableModule,
        MatTabsModule,
        MatCheckboxModule,
        MatToolbarModule,
        MatCardModule,
        MatFormFieldModule,
        MatProgressSpinnerModule,
        MatProgressBarModule,
        MatInputModule,
        MatPaginatorModule,
        MatGridListModule,
        MatSidenavModule,
        MatListModule,
        MatChipsModule,
        MatStepperModule,
        MatMenuModule,
        MatRippleModule,
        MatExpansionModule,
    ],
    providers: [
        { provide: MAT_DATE_LOCALE, useValue: 'en-GB' }
    ]
})
export class MaterialsModule { }
// {provide: MAT_DATE_LOCALE, useValue: 'en-GB'},
