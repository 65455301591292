<div class="page-container" fxLayout="column wrap" fxLayoutGap="5vh">

    <mat-card fxFlex="50" fxFlex.md="50" fxFlex.sm="50" fxFlex.xs="50" class="leave-cards">
        <h1>{{learnerName}}</h1>
        <h2>Holiday request</h2>

        <div class="form">
            <label id="radio-label">Absence type</label>
            <mat-radio-group aria-labelledby="example-radio-group-label" class="example-radio-group"
                [(ngModel)]="absenceTypeSelected">
                <mat-radio-button class="example-radio-button" *ngFor="let absenceType of absenceTypes"
                    [value]="absenceType">
                    {{absenceType}}
                </mat-radio-button>
            </mat-radio-group>

            <div [formGroup] = "range">
                <div>
                    <mat-form-field appearance="fill" style="margin-right: 25px;" >
                        <mat-label>{{absenceTypeSelected}} Start</mat-label>
                        <input formControlName="start" matInput [matDatepicker]="datepicker" [readonly]="true" (click)="datepicker.open()" style="cursor: pointer;" (dateChange)="dateChange()">
                        <mat-datepicker-toggle matSuffix [for]="datepicker"></mat-datepicker-toggle>
                        <mat-datepicker #datepicker>
                        </mat-datepicker>
                    </mat-form-field>

                    <mat-form-field appearance="fill" style="width: 200px;">
                        <mat-label>From</mat-label>
                        <mat-select (selectionChange)="halfDayStartChanged()" formControlName="start9amOrMidday">
                            <mat-option value="9am">9am</mat-option>
                            <mat-option value="Midday (12pm)">Midday (12pm)</mat-option>
                        </mat-select>
                    </mat-form-field>

                </div>

                <div>
                    <mat-form-field appearance="fill" style="margin-right: 25px;">
                        <mat-label>{{absenceTypeSelected}} End</mat-label>
                        <input formControlName="end" matInput [matDatepicker]="datepickerend" [readonly]="true" (click)="datepickerend.open()" style="cursor: pointer;" (dateChange)="dateChange()">
                        <mat-datepicker-toggle matSuffix [for]="datepickerend"></mat-datepicker-toggle>
                        <mat-datepicker #datepickerend >
                        </mat-datepicker>
                    </mat-form-field>


                    <mat-form-field appearance="fill" style="width: 200px;">
                        <mat-label>To</mat-label>
                        <mat-select (selectionChange)="halfDayEndChanged()" formControlName="endMiddayOrEnd">
                            <mat-option value="Midday (12pm)">Midday (12pm)</mat-option>
                            <mat-option value="End of day">End of day</mat-option>
                        </mat-select>
                    </mat-form-field>

                </div>
            </div>

            <!-- <div>
                <mat-checkbox style="margin-right: 10px;" [(ngModel)]="isHalfDayStart" (change)="halfDayStartChanged()">
                    Is half day <span *ngIf="duration && duration > 1">start</span> </mat-checkbox>
            </div>
            <div>
                <mat-checkbox style="margin-right: 10px;" [(ngModel)]="isHalfDayEnd" (change)="halfDayEndChanged()"
                    *ngIf="duration > 1 || (isHalfDayStart && duration == 1)">
                    Is half day end</mat-checkbox>
            </div>
            <div>
                <label id="duration-label">Duration</label>
                <button mat-button type="button" *ngIf="isDisplayOneDayButton" (click)="selectOneDayDuration()">
                    <span *ngIf="!isHalfDayStart">{{'selectOneDayDuration' | translate }}</span>
                    <span *ngIf="isHalfDayStart">{{'selectHalfDayDuration' | translate }} </span>
                </button>
                <div *ngIf="!duration" class="space-holder">

                </div> -->
                <div [hidden]="!duration" class="space-holder">{{duration}} days</div>
            <!-- </div> -->
        </div>



        <div align="center">
            <button mat-button type="button" routerLink="/my-account">{{ 'backToAccountHomePage' | translate }}</button>
        </div>

        <div align="center" class="margin-top">

            <button mat-flat-button color="accent" type="button" (click)="bookLeave()" class="button-margin"
                [disabled]="isLoading || !endAfterStart">
                <span>{{ 'bookLeave' | translate }}</span>
                <mat-spinner class="loading-spinner" style="margin:0 auto;" *ngIf="isLoading" diameter="20">
                </mat-spinner>
            </button>


        </div>

        <!-- </form> -->
    </mat-card>

    <mat-card fxFlex="45" fxFlex.md="45" fxFlex.sm="100" fxFlex.xs="100" class="holidays leave-cards" >
        <h2>Holidays</h2>
        <mat-spinner class="loading-spinner" style="margin:0 auto;" *ngIf="isLoadingHolidayInfo" diameter="20">
        </mat-spinner>
        <div *ngIf="!isLoadingHolidayInfo">
            <h3>Upcoming</h3>
            <div  *ngIf="holidayInfo.upcomingLeave.length == 0">
                None upcoming
            </div>
            <div *ngIf="holidayInfo.upcomingLeave.length > 0">
                <div fxLayout="row" class="headers">
                    <div fxFlex="25">Start date</div>
                    <div fxFlex="25">End date</div>
                    <div fxFlex="25">Duration</div>
                    <div fxFlex="25">Is approved</div>

                </div>
                <div *ngFor="let upcoming of holidayInfo.upcomingLeave">
                    <div fxLayout="row" fxLayoutGap="20px">
                        <div fxFlex="25">{{upcoming.startDate}}</div>
                        <div fxFlex="25">{{upcoming.endDate}}</div>
                        <div fxFlex="25">{{ upcoming.duration}} days</div>
                        <div fxFlex="25">{{ upcoming.isApproved}}</div>
                    </div>
                </div>
            </div>
            <h3>Summary</h3>
            <div fxLayout="row" class="headers">
                <div fxFlex="25">Total (days)</div>
                <div fxFlex="25">Taken (days)</div>
                <div fxFlex="25">Booked (days)</div>
                <div fxFlex="25">Remaining (days)</div>


            </div>

            <div fxLayout="row">
                <div fxFlex="25">{{holidayInfo.totalLeaveAvailableForThisPeriod}}</div>
                <div fxFlex="25">{{holidayInfo.leaveTaken}}</div>
                <div fxFlex="25">{{holidayInfo.leaveBooked}}</div>
                <div fxFlex="25">{{ holidayInfo.leaveRemaining}}</div>

            </div>
        </div>

    </mat-card>

</div>
