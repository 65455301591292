<mat-card style="max-width: 50vw; margin: 25vh auto;"  align="middle">
    <h1>Email my PIN</h1>
    <form [formGroup]="passwordForm" (ngSubmit)="submitPasswordForm()">
        <div class="row">

            <mat-form-field class="example-full-width" style="max-width: 300px;">
                <mat-label>{{ 'email' | translate }}</mat-label>
        <input matInput formControlName="email" name="email" required id="email" type="text" placeholder="{{'emailPlaceholder' | translate}}"
        />
        </mat-form-field>

        </div>
        <div align="center">
            <button mat-button type="button" routerLink="/login/{{locationId}}">{{ 'backToLogin' | translate }}</button>
        </div>
    <div align="center" class="margin-top">
     
        <button mat-flat-button color="primary" type="submit" class="button-margin" [disabled]="sendingEmail || !passwordForm.valid">
            <span *ngIf="!sendingEmail">{{ 'emailPIN' | translate }}</span> 
            <mat-spinner class="loading-spinner" style="margin:0 auto;" *ngIf="sendingEmail" diameter="20"></mat-spinner>
        </button>
    </div>
    </form>
</mat-card>