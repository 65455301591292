import { Component, OnInit } from '@angular/core';
import { ActivatedRoute, Router } from '@angular/router'

@Component({
  selector: 'app-landing-page',
  templateUrl: './landing-page.component.html',
  styleUrls: ['./landing-page.component.scss']
})
export class LandingPageComponent implements OnInit {

  centresGrouped: any[] = [];

  constructor(
    private route: ActivatedRoute,
    private router: Router
  ) { }

  ngOnInit(): void {
    this.route.data.subscribe(data => {
      const centreData = data.centres.sort((a, b) => a.name.localeCompare(b.name));
      const groups: Set<string> = new Set(data.centres.map(centre => centre.subcontractor));
      groups.forEach(group => {
        const values = centreData.filter(item => item.subcontractor === group);
        this.centresGrouped.push({name: [group], centres: values});
      });
    });
  }

  centreSelected(centre) {
    console.log(centre.value.id);
    this.router.navigate([`/login/${centre.value.id}`]);
  }

}
