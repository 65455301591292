import { Component, OnInit } from '@angular/core';
import { Router } from '@angular/router';

@Component({
  selector: 'app-welcome',
  templateUrl: './welcome.component.html',
  styleUrls: ['./welcome.component.scss'],
})
export class WelcomeComponent implements OnInit {
  centreName
  learnerName
  locationId

  constructor(private router: Router) { }
  public $time: Date = new Date();

  ngOnInit(): void {
    this.centreName = localStorage.getItem("centreName")
    this.learnerName = localStorage.getItem("learnerName")
    this.locationId = localStorage.getItem("locationId")
  }

  backToLogin(){
    localStorage.clear()
    this.router.navigate([`/login/${this.locationId}`]);
  }
}
