<div>

  <mat-card style="margin-top: 5px;" class="alert-card" *ngIf="leaveToApproveByLearner?.length > 0">
    You have leave to approve
    <button  mat-flat-button color="primary"  class="view-leave-to-approve" type="button"  routerLink="/approve-leave" >
      {{ 'view' | translate }}
    </button>
  </mat-card>

  <div style="display: flex; justify-content: center; align-items: center; flex: 1; margin-top: 10px;">
    <mat-card class="account-centre-card" align="middle">
        <h1>{{learnerName}}</h1>
        <h2>My Account</h2>

        <p style="margin: 0;">{{ $time  | date : 'dd/MM/y - HH:mm' }}</p>
        <h3 style="margin-top: 0;" *ngIf="isSignedIn">(You are signed in to {{centreName}})</h3>
        <span *ngIf="!isSignedIn; else satisfaction">
          <h3 style="margin-top: 0;">(You have not yet signed in)</h3>
          <!-- Dont show meal allowance for virtual classroom -->
          <div *ngIf="this.locationId !== '107'">
            <p style="padding: 0 36px;">By signing in I understand I will be eligible for and receive £3.90 meal allowance upon completing the 4 hour minimum attendance requirement. I confirm I will spend the meal allowance on lunch as intended.</p>
            <p style="padding: 0 36px;">NOTE: Meal allowance will be paid weekly, along side your allowance and travel expenses.</p>
          </div>
        </span>

        <ng-template #satisfaction>
          <div *ngIf="this.isACT">
            <h3>How was your day with ACT today?</h3>
              <div style="display: flex; flex-direction: row; gap: 10px; justify-content: center; margin-bottom: 10px;">
                <button *ngFor="let button of satisfactionButtons" mat-icon-button class="satisfaction-button"
                  color="primary"
                  (click)="setSatisfaction(button.value)"
                  [disabled]="isLoading || disable"
                >
                  <mat-icon class="satisfaction-icon">
                    <div style="border-radius: 50%;" [ngStyle]="button.value === this.satisfactionValue ? {background: '#f37a54'} : ''">
                      <img class="icon-img" src="{{button.src}}"/>
                    </div>
                  </mat-icon>
                </button>
              </div>
              <div style="padding: 0 16px;">
                <mat-form-field style="width: 100%;">
                  <mat-label>Tell us about your experience today!</mat-label>
                  <textarea matInput [(ngModel)]="this.satisfactionComment" rows="4"></textarea>
                </mat-form-field>
              </div>
          </div>
        </ng-template>

        <div align="center" style="display: flex; flex-direction: column;">
            <div class="button-container">
              <button mat-flat-button color="primary" type="button" (click)="signIn()" class="button-margin" *ngIf="!isSignedIn" [disabled]="isLoading || disable">
                  <span >{{ 'signIn' | translate }} to {{centreName}}</span>
                  <mat-progress-bar *ngIf="isLoading" mode="indeterminate" class="button-progress-bar"></mat-progress-bar>
              </button>

              <button mat-flat-button color="primary" type="button" (click)="signOut()" class="button-margin" *ngIf="isSignedIn" [disabled]="isLoading || disable">
                  <span >{{ 'signOut' | translate }} from {{centreName}}</span>
                  <mat-progress-bar *ngIf="isLoading" mode="indeterminate" class="button-progress-bar"></mat-progress-bar>
              </button>


              <button mat-flat-button color="accent" type="button"  routerLink="/book-leave" class="button-margin"  [disabled]="isLoading" style="margin-right: 0">
                  <span >{{ 'bookLeave' | translate }}</span>
              </button>
            </div>

            <button mat-button type="button" class="button-margin-bottom" (click)="backToLogin()" >{{ 'Back To Login Page' | translate }}</button>
        </div>
    </mat-card>
  </div>
</div>

