import { Injectable } from '@angular/core';
import { environment } from 'src/environments/environment';
import { HttpClient, HttpHeaders } from '@angular/common/http';
import { SignOutLearner } from 'src/app/shared/interfaces/sign-out-learner';

@Injectable({
  providedIn: 'root'
})
export class UserService {
  apiUrl;
  constructor(  private http: HttpClient) {
    this.apiUrl = environment.API_URL;
  }

  public loginUser(locationId: number, userId: number) {

    const url = `${environment.API_URL}loginLearner`;
    const options = this.getOptionsWithoutAccessToken();
    let data = {locationId: locationId, userId: userId}
    return this.http.post<any>(url, data, options);
  }


  public emailMeMyPin(email: string, locationId: number) {

    const url = `${environment.API_URL}emailMeMyPin`;
    const options = this.getOptionsWithoutAccessToken();
    let data = {email: email, locationId: locationId}
    return this.http.post<any>(url, data, options);
  }


  public signinLearner(locationId: number, userId: number) {

    const url = `${environment.API_URL}signinLearner`;
    const options = this.getOptionsWithoutAccessToken();
    let data = {locationId: locationId, userId: userId}
    return this.http.post<any>(url, data, options);
  }

  public signoutLearner(signOutLearnerData: SignOutLearner) {
    return this.http.post<SignOutLearner>(`${environment.API_URL}v2/LearnerVision/SignOut`, signOutLearnerData, this.getOptionsWithoutAccessToken());
  }

  public getLeaveDetails(userId: number) {
    const url = `${environment.API_URL}getLearnerLeaveDetails`;
    const options = this.getOptionsWithoutAccessToken();
    let data = {userId: userId}
    return this.http.post<any>(url, data, options);
  }

  public getLeavePendingLearnerApprovalDetails(userId: number) {
    const url = `${environment.API_URL}getLeavePendingLearnerApprovalDetails`;
    const options = this.getOptionsWithoutAccessToken();
    let data = {userId: userId}
    return this.http.post<any>(url, data, options);
  }

  public approveLeaveAsLearner(userId: number, leaveToApprove: any) {
    const url = `${environment.API_URL}approveLeaveAsLearner`;
    const options = this.getOptionsWithoutAccessToken();
    let data = {userId: userId, approveLeaveAsLearner: leaveToApprove}
    return this.http.post<any>(url, data, options);
  }

  public bookLeave(userId: number, startDate: string, endDate: string, isHoliday: boolean, duration: string, isHalfDayStart: boolean, isHalfDayEnd: boolean) {

    const url = `${environment.API_URL}bookLearnerLeave`;
    const options = this.getOptionsWithoutAccessToken();
    let data = { userId: userId, startDate: startDate, endDate: endDate, isHoliday: isHoliday, leaveDuration: duration, isHalfDayStart: isHalfDayStart, isHalfDayEnd: isHalfDayEnd}
    return this.http.post<any>(url, data, options);
  }


  public getLocationDetails(locationId) {
    console.log("getting location")
    const url = `${environment.API_URL}getLocationDetails/${locationId}`;
    const options = this.getOptionsWithoutAccessToken();
    let data = {locationId: locationId}
    return this.http.get<any>(url, options);
  }

  getOptionsWithoutAccessToken() {
    let headers = new HttpHeaders()
      .set('Content-Type', 'application/json')
      .set('Accept', 'application/json')
    let options = { headers: headers };
    return options;
  }


}
