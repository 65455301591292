import { Component, OnInit, ViewChild } from '@angular/core'
import { FormGroup, FormBuilder, Validators } from '@angular/forms'
import { UserService } from '../../user.service'
import { ActivatedRoute, Router } from '@angular/router'
import { MatSnackBar } from '@angular/material/snack-bar'

@Component({
  selector: 'app-login',
  templateUrl: './login.component.html',
  styleUrls: ['./login.component.scss']
})
export class LoginComponent implements OnInit {
  @ViewChild('loginType') loginType
  loginForm: FormGroup
  loading = false
  submitted = false
  returnUrl: string
  error = ''
  hide = true
  isDevelopment: boolean = false
  location
  locationId: number;
  activeURL: string
  isLoginFail: boolean
  subcontractorId: number

  constructor (
    private UserService: UserService,
    private router: Router,
    private route: ActivatedRoute,
    private _snackBar: MatSnackBar,
    private fb: FormBuilder
  ) {
    // if (this.authenticationService.userValue) {
    //   this.router.navigate(['/students']);
    // }
  }

  setLayoutBasedOnURL () {
    var url = window.location.href
  }

  ngOnInit (): void {
    this.activeURL = this.router.url

    const pin = this.route.snapshot.params.pin

    this.route.data.subscribe(data => {
      if (data.location) {
        console.log('initial data', data)
        this.location = data.location
        this.locationId = data.location.centreid
        this.subcontractorId = data.location.subcontractorid;
      }

      console.log(data.location);

      if (this.locationId === undefined || this.locationId === null) {
        this.router.navigate(['/'])
      }
    })

    //  this.loginType['value'] = 'sweetAdmin';
    this.loginForm = this.fb.group({
      userId: [
        pin,
        [Validators.required, Validators.maxLength(4), Validators.minLength(4)]
      ]
    })

    if (this.activeURL == '/') {
      this.loginForm.controls['userId'].disable()
    }
  }

  updatePassword () {
    console.log(this.loginForm.value)
  }

  isPasswordGroupTouchedDirtyAndInvalid (propertyName) {
    return (
      (this.loginForm.get(propertyName).touched ||
        this.loginForm.get(propertyName).dirty) &&
      !this.loginForm.get(propertyName).valid
    )
  }

  login () {
    this.loading = true

    var userId = this.loginForm.value.userId

    this.isLoginFail = false

    this.UserService.loginUser(
      this.route.snapshot.params.locationId,
      userId
    ).subscribe(response => {
      if (response.wasSuccessful) {
        localStorage.setItem('locationId', this.locationId.toString());
        localStorage.setItem('userId', userId);
        localStorage.setItem('subcontractorId', this.subcontractorId.toString());
        localStorage.setItem('centreName', response.centreName);
        localStorage.setItem('learnerName', response.learnerName);
        localStorage.setItem('isSignedIn', response.isSignedIn);
        localStorage.setItem('IdOfSignInRecord', response.idOfSignInRecord);
        this.router.navigate(['welcome'])
      } else {
        this._snackBar.open(response.failureReason, 'Error', {
          duration: 3000
        })
        this.loading = false
        this.isLoginFail = true
      }
    })
  }

}
